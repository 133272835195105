// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lp-1-f-js": () => import("./../../../src/pages/LP1F.js" /* webpackChunkName: "component---src-pages-lp-1-f-js" */),
  "component---src-pages-lp-1-js": () => import("./../../../src/pages/LP1.js" /* webpackChunkName: "component---src-pages-lp-1-js" */),
  "component---src-pages-lp-2-js": () => import("./../../../src/pages/LP2.js" /* webpackChunkName: "component---src-pages-lp-2-js" */),
  "component---src-pages-lp-blue-js": () => import("./../../../src/pages/LPBlue.js" /* webpackChunkName: "component---src-pages-lp-blue-js" */),
  "component---src-pages-lp-violet-js": () => import("./../../../src/pages/LPViolet.js" /* webpackChunkName: "component---src-pages-lp-violet-js" */)
}

